export const theme = {
    foreground: '#eff0eb',
    background: '#282a36',
    selection: '#97979b33',
    black: '#282a36',
    brightBlack: '#686868',
    red: '#ff5c57',
    brightRed: '#ff5c57',
    green: '#5af78e',
    brightGreen: '#5af78e',
    yellow: '#f3f99d',
    brightYellow: '#f3f99d',
    blue: '#57c7ff',
    brightBlue: '#57c7ff',
    magenta: '#ff6ac1',
    brightMagenta: '#ff6ac1',
    cyan: '#9aedfe',
    brightCyan: '#9aedfe',
    white: '#f1f1f0',
    brightWhite: '#eff0eb',
    cursor: '#eff0eb',
    cursorAccent: '#282a36'
};
export class LocalSettings {
    get disableCrt() {
        return localStorage.getItem('disable-crt') === 'true';
    }
    set disableCrt(val) {
        localStorage.setItem('disable-crt', String(val));
    }
    get disableFullscreen() {
        return localStorage.getItem('disable-fs') === 'true';
    }
    set disableFullscreen(val) {
        localStorage.setItem('disable-fs', String(val));
    }
    get disableAudio() {
        return localStorage.getItem('disable-audio') === 'true';
    }
    set disableAudio(val) {
        localStorage.setItem('disable-audio', String(val));
    }
}
