var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Terminal } from 'xterm';
import { LocalSettings, theme } from './settings';
import * as $ from 'jquery';
import { FitAddon } from 'xterm-addon-fit';
import { WebglAddon } from 'xterm-addon-webgl';
import { RenderManager } from './render';
import { FileSystemManager } from './file-system';
import { LE, randomCloseDate, sanitizeCommand, sleep, webglSupport } from '../util/util';
import { AnsiCodes, Colors } from '../util/ansi';
import { EventEmitter } from 'events';
export class EmulatedTerminal extends Terminal {
    constructor(element, username, fsRoot, commands) {
        super({
            // fontFamily: '"Cascadia Code", Menlo, monospace',
            rendererType: 'dom',
            allowTransparency: true,
            cursorBlink: true,
            fontSize: 20,
            theme: theme,
            bellStyle: "sound",
        });
        this.lastCommand = '';
        this.currentCommand = '';
        this.domain = "@re-init.net";
        this.started = false;
        this.events = new EventEmitter();
        this.fsManager = new FileSystemManager({ name: '/home/' + username, fsItem: fsRoot });
        this.commands = commands;
        this.username = username;
        this.lastLogin = randomCloseDate();
        this.settings = new LocalSettings();
        this.fitAddon = new FitAddon();
        this.webglAddon = new WebglAddon();
        this.termDiv = element;
        if (this.settings.disableAudio) {
            this.setOption('bellStyle', 'none');
        }
        this.loadAddon(this.fitAddon);
        this.open(this.termDiv);
        this.renderManager = new RenderManager(this);
        if (webglSupport()) {
            this.loadAddon(this.webglAddon);
            // Workaround for blinking cursor
            const cursorRenderLayer = this.webglAddon._renderer._renderLayers[1];
            cursorRenderLayer.onOptionsChanged(this);
            const rCallback = cursorRenderLayer._cursorBlinkStateManager._renderCallback;
            // May need to repeat after cursorBlink is disposed.
            cursorRenderLayer._cursorBlinkStateManager._renderCallback = () => {
                rCallback();
                this.renderManager.renderAll();
            };
        }
        this.fitAddon.fit();
        this.focus();
        this.updatePrompt();
        document.body.onresize = this.onBodyResize.bind(this);
        if (this.settings.disableCrt) {
            this.termDiv.classList.remove('crt-anim');
        }
    }
    enable() {
        this.termDiv.onkeyup = this.keyEvent.bind(this);
    }
    unbind() {
        this.termDiv.onkeyup = undefined;
    }
    disable() {
        this.unbind();
        this.started = false;
        this.renderManager.renderCursor = false;
        this.events.emit('disable');
    }
    keyEvent(e) {
        switch (e.key) {
            case "ArrowUp":
                if (this.lastCommand === '')
                    break;
                this.currentCommand = this.lastCommand;
                this.lastCommand = "";
                this.write(this.currentCommand);
                break;
            case "Tab":
                if (this.currentCommand.trim() === '')
                    break;
                const args = sanitizeCommand(this.currentCommand);
                this.resolveChain(args, this.resolverChain);
                this.write(AnsiCodes.BEEP);
                break;
            case "Enter":
                this.lastCommand = this.currentCommand;
                this.currentCommand = '';
                this.write('\r\n');
                this.processCommand(this.lastCommand);
                break;
            case "Backspace":
                if (!this.currentCommand)
                    break;
                this.write('\b \b\u0007');
                this.currentCommand = this.currentCommand.substring(0, this.currentCommand.length - 1);
                break;
            default:
                const char = e.key;
                if (char.length > 1) {
                    break;
                }
                this.write(char + AnsiCodes.BEEP);
                this.currentCommand = this.currentCommand + char;
        }
    }
    resolveChain(args, chain) {
        let target = args[0];
        while (args.length > 1) {
            if (!chain.children || !chain.children[args[0]])
                return;
            args = args.splice(1);
            const child = chain.children[target];
            if (child.criteria && !child.criteria(args)) {
                return;
            }
            chain = chain.children[target];
            target = args[0];
        }
        const all = chain.possibilities instanceof Function ? chain.possibilities() : chain.possibilities;
        const currPoss = Object.keys(all).filter(c => c.startsWith(target));
        if (currPoss.length === 1 && target.length < currPoss[0].length) {
            const toWrite = currPoss[0].substring(target.length);
            this.write(toWrite);
            this.currentCommand += toWrite;
        }
        else if (currPoss.length > 1) {
            let same = '';
            for (let i = 0; i < currPoss[0].length; i++) {
                if (currPoss.every(posi => posi.startsWith(same + currPoss[0][i]))) {
                    same += currPoss[0][i];
                }
            }
            this.write('\r\n');
            this.write(currPoss.join(' ') + '\r\n');
            this.write(this.currentPrompt);
            this.write(this.currentCommand);
            if (same !== '') {
                same = same.substring(target.length);
                this.write(same);
                this.currentCommand += same;
            }
        }
    }
    ;
    updatePrompt() {
        this.currentPrompt = Colors.BRIGHT_GREEN + this.username + this.domain + Colors.END + ':'
            + Colors.BRIGHT_BLUE + this.fsManager.currentPath + Colors.END + '$ ';
    }
    onBodyResize() {
        this.fitAddon.fit();
        this.focus();
        this.resetCursor();
    }
    resetCursor() {
        // Fix for blinking cursor
        if (webglSupport()) {
            setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                this.webglAddon._renderer._renderLayers[1].onBlur(this);
                yield sleep(50);
                this.webglAddon._renderer._renderLayers[1].onFocus(this);
            }), 50);
        }
    }
    write(data, callback) {
        // To introduce margin on the left.
        if (typeof data === 'string') {
            data = data.replace(/\r\n/g, '\r\n\t');
            // Margin on bottom. (save cursor, two blank lines, move cursor back)
            data += '\u001B[s' + LE + LE + LE + '\u001B[u';
        }
        super.write(data, callback);
    }
    commandCallback(response) {
        if (response !== '') {
            this.write(response);
            if (!response.endsWith('\r\n'))
                this.write('\r\n');
        }
        this.write(this.currentPrompt + AnsiCodes.BEEP);
    }
    processCommand(command) {
        this.pendingPromise = $.Deferred();
        const that = this;
        this.pendingPromise.done(function (resolvedResponse) {
            that.commandCallback(resolvedResponse);
        });
        this.executeCommand(command, this.pendingPromise);
    }
    executeCommand(command, pendingPromise) {
        const args = sanitizeCommand(command);
        command = args[0];
        args.shift();
        if (this.commands[command] !== undefined) {
            this.commands[command].execute(this, pendingPromise, args);
            return;
        }
        pendingPromise.resolve('Command "' + command + '" not found\r\n');
    }
}
