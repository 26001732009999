import { randomCloseDate } from '../util/util';
export class FileSystemManager {
    constructor(root) {
        this.root = root;
        this.currentNode = root;
        this.walk(root.fsItem, fs => {
            fs.date = randomCloseDate();
        });
    }
    walk(fs, op) {
        op(fs);
        if (fs.children) {
            for (const childName in fs.children) {
                this.walk(fs.children[childName], op);
            }
        }
    }
    listCurrentDir() {
        return this.currentNode.fsItem.children;
    }
    get currentItem() {
        return this.currentNode.fsItem;
    }
    get currentParent() {
        return this.currentNode.parent ? this.currentNode.parent.fsItem : null;
    }
    changeNode(name) {
        this.currentNode = { name: name, parent: this.currentNode, fsItem: this.currentNode.fsItem.children[name] };
    }
    changeNodeParent() {
        if (this.currentNode.parent) {
            this.currentNode = this.currentNode.parent;
        }
    }
    changeNodeRoot() {
        this.currentNode = this.root;
    }
    get currentPath() {
        if (this.currentNode.fsItem === this.root.fsItem) {
            return '~';
        }
        let path = '';
        let node = this.currentNode;
        while (node) {
            path = node.name + '/' + path;
            node = node.parent;
        }
        return path;
    }
}
