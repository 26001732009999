import { WebsocketBuilder } from 'websocket-ts';
import { EventEmitter } from 'events';
export class HackChat {
    constructor(login, channel) {
        this.login = login;
        if (login.indexOf('#') !== -1) {
            this.nick = login.split('#')[0];
        }
        else {
            this.nick = login;
        }
        this.channel = channel;
        this.events = new EventEmitter();
        this.onlineList = [];
    }
    send(data) {
        this.ws.send(JSON.stringify(data));
    }
    message(text) {
        this.send({ cmd: 'chat', text: text });
    }
    whisper(to, text) {
        this.send({ cmd: 'whisper', nick: to, text: text });
    }
    move(to) {
        this.send({ cmd: 'move', channel: to });
    }
    changeNick(nick) {
        this.send({ cmd: 'changenick', nick: nick });
    }
    connect() {
        const host = window.location.hostname === 'localhost' ? '192.168.1.20' : window.location.hostname;
        const protocol = window.location.protocol === 'http:' ? 'ws' : 'wss';
        const port = protocol === 'ws' ? 6060 : 443;
        this.ws = new WebsocketBuilder(`${protocol}://${host}:${port}`)
            .onMessage((i, e) => {
            const data = JSON.parse(e.data);
            switch (data.cmd) {
                case 'info':
                    this.events.emit('info', data.text, data.type);
                    break;
                case 'warn':
                    this.events.emit('warn', data.text);
                    break;
                case 'onlineSet':
                    this.onlineList = data.nicks;
                    if (data.users)
                        this.trip = data.users.find((u) => u.nick === this.nick).trip;
                    this.events.emit('onlineSet', data.nicks);
                    break;
                case 'onlineAdd':
                    this.onlineList.push(data.nick);
                    this.events.emit('onlineAdd', data.nick, data.trip);
                    break;
                case 'onlineRemove':
                    this.onlineList.splice(this.onlineList.indexOf(data.nick), 1);
                    this.events.emit('onlineRemove', data.nick);
                    break;
                case 'chat':
                    this.events.emit('chat', data.nick, data.text, data.trip);
                    break;
            }
        })
            .onOpen((i, e) => {
            this.send({ 'cmd': 'join', 'channel': this.channel, 'nick': this.login });
            this.pingRef = setInterval(() => {
                this.send({ "cmd": "ping" });
            }, 60000);
        })
            .build();
    }
    disconnect() {
        clearInterval(this.pingRef);
        this.ws.close();
    }
}
