import SamJs from 'sam-js';
import { LE } from '../util/util';
export class Say {
    constructor() {
        this.sam = new SamJs({
            debug: 0,
            pitch: 90,
            speed: 100,
            mouth: 128,
            throat: 128
        });
    }
    execute(term, args) {
        term.unbind();
        this.sam.speak(args.join(' ')).catch(() => {
            term.write('Error with input.' + LE);
        }).finally(() => {
            term.write(term.currentPrompt);
            term.enable();
        });
    }
}
