export function randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
export function sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}
export function extend(target, count, delim = ' ', left = false) {
    while (target.length < count)
        target = left ? delim + target : target + delim;
    return target;
}
export function randomCloseDate() {
    const date = new Date();
    date.setDate(date.getDate() - randomNumber(1, 3));
    date.setHours(date.getHours() + randomNumber(-6, +5));
    date.setMinutes(date.getMinutes() + randomNumber(-3, +3));
    date.setSeconds(date.getSeconds() + randomNumber(-3, +3));
    return date;
}
let webSup = undefined;
export function webglSupport() {
    if (webSup !== undefined)
        return webSup;
    try {
        const canvas = document.createElement('canvas');
        return webSup = !!window.WebGLRenderingContext &&
            !!canvas.getContext('webgl2');
    }
    catch (e) {
        return webSup = false;
    }
}
export function sanitizeCommand(str) {
    return str.trim().split(' ').filter(value => value !== '');
}
export const LE = '\r\n';
