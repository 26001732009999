import { AnsiCodes, Colors } from '../util/ansi';
import { LE, sanitizeCommand } from '../util/util';
import { HackChat } from '../hackchat/hackchat';
export class Chat {
    execute(term, args) {
        term.unbind();
        const writeTermMsg = function (txt) {
            term.write(AnsiCodes.CLEAR_LINE + AnsiCodes.CURSOR_START + '\t' + txt + LE + term.currentPrompt + term.currentCommand + AnsiCodes.BEEP);
        };
        const clearPrompt = function () {
            term.currentCommand = '';
            term.write(AnsiCodes.CLEAR_LINE + AnsiCodes.CURSOR_START + '\t' + term.currentPrompt + AnsiCodes.BEEP);
        };
        const chat = new HackChat(args[0] !== undefined ? args[0] : term.username, args[1] !== undefined ? args[1] : 'reinit');
        term.currentPrompt = '>> ';
        term.write(AnsiCodes.CLEAR + LE + LE + term.currentPrompt);
        term.termDiv.onkeyup = (e) => {
            const key = e.key;
            switch (key) {
                case 'Enter':
                    if (term.currentCommand.startsWith('/')) {
                        const args = sanitizeCommand(term.currentCommand.substring(1));
                        const cmd = args[0];
                        args.shift();
                        switch (cmd) {
                            case 'exit':
                                chat.disconnect();
                                term.termDiv.onkeyup = undefined;
                                term.currentCommand = '';
                                term.updatePrompt();
                                term.write(AnsiCodes.CLEAR + LE + LE + term.currentPrompt);
                                term.enable();
                                chat.events.removeAllListeners('chat');
                                chat.events.removeAllListeners('onlineAdd');
                                chat.events.removeAllListeners('onlineRemove');
                                chat.events.removeAllListeners('onlineSet');
                                return;
                            case 'w':
                            case 'whisper':
                                if (args.length < 2) {
                                    term.currentCommand = '';
                                    writeTermMsg(Colors.RED + '* Wrong number of arguments' + Colors.END);
                                    return;
                                }
                                const target = args[0];
                                args.shift();
                                chat.whisper(target, args.join(' '));
                                clearPrompt();
                                return;
                            case 'move':
                                if (args.length < 1) {
                                    term.currentCommand = '';
                                    writeTermMsg(Colors.RED + '* Wrong number of arguments' + Colors.END);
                                    return;
                                }
                                chat.channel = args[0];
                                chat.move(args[0]);
                                clearPrompt();
                                break;
                            case 'cn':
                            case 'changenick':
                                if (args.length < 1) {
                                    term.currentCommand = '';
                                    writeTermMsg(Colors.RED + '* Wrong number of arguments' + Colors.END);
                                    return;
                                }
                                chat.login = args[0];
                                if (chat.login.indexOf('#') !== -1) {
                                    chat.nick = chat.login.split('#')[0];
                                }
                                else {
                                    chat.nick = chat.login;
                                }
                                chat.changeNick(args[0]);
                                clearPrompt();
                                break;
                        }
                    }
                    const msg = term.currentCommand;
                    chat.message(msg);
                    clearPrompt();
                    break;
                case "Backspace":
                    if (!term.currentCommand)
                        break;
                    term.write('\b \b\u0007');
                    term.currentCommand = term.currentCommand.substring(0, term.currentCommand.length - 1);
                    break;
                default:
                    if (key.length > 1)
                        break;
                    term.currentCommand += key;
                    term.write(key + AnsiCodes.BEEP);
                    break;
            }
        };
        chat.events.on('warn', (text) => {
            writeTermMsg(Colors.RED + '* ' + text + Colors.END);
        });
        chat.events.on('info', (text, type) => {
            let color = Colors.RED;
            switch (type) {
                case 'whisper':
                    color = Colors.CYAN;
                    break;
            }
            writeTermMsg(color + '* ' + text + Colors.END);
        });
        chat.events.on('chat', (nick, text, trip) => {
            writeTermMsg((trip ? Colors.BLUE + trip + Colors.END + ' ' : '') + nick + ': ' + text);
        });
        chat.events.on('onlineAdd', (nick, trip) => {
            writeTermMsg(Colors.RED + '* ' + nick + (trip !== 'null' ? ' (' + trip + ')' : '') + ' joined' + Colors.END);
        });
        chat.events.on('onlineRemove', (nick) => {
            writeTermMsg(Colors.RED + '* ' + nick + ' left' + Colors.END);
        });
        chat.events.on('onlineSet', (nicks) => {
            writeTermMsg(Colors.RED + '* Joined "' + chat.channel + '" as ' + chat.nick + Colors.END);
            writeTermMsg(Colors.RED + '* Online: ' + nicks.join(', ') + Colors.END);
        });
        chat.connect();
    }
}
