export var Colors;
(function (Colors) {
    Colors["BLUE"] = "\u001B[34m";
    Colors["GREEN"] = "\u001B[32m";
    Colors["RED"] = "\u001B[31m";
    Colors["MAGENTA"] = "\u001B[35m";
    Colors["YELLOW"] = "\u001B[33m";
    Colors["CYAN"] = "\u001B[36m";
    Colors["BRIGHT_BLUE"] = "\u001B[34;1m";
    Colors["BRIGHT_GREEN"] = "\u001B[32;1m";
    Colors["END"] = "\u001B[0m";
})(Colors || (Colors = {}));
export var AnsiCodes;
(function (AnsiCodes) {
    AnsiCodes["BEEP"] = "\u0007";
    AnsiCodes["CLEAR"] = "\u001Bc";
    AnsiCodes["CLEAR_LINE"] = "\u001B[2K";
    AnsiCodes["CURSOR_START"] = "\u001B[G";
})(AnsiCodes || (AnsiCodes = {}));
