var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as $ from 'jquery';
import { LE, randomNumber, sleep, webglSupport } from './util/util';
import { EmulatedTerminal } from './terminal/terminal';
import { commands } from './terminal/command';
$(function () {
    // Remove console.log so SAM-JS doesnt output anything.
    // Should probably use https://stackoverflow.com/questions/66057767/how-to-remove-disable-console-log-from-a-node-module
    var oldConsoleLog = window.console.log;
    window.console.log = () => { };
    let username = undefined;
    $.ajax({
        async: false,
        url: 'https://randomuser.me/api/',
        dataType: 'json',
        success: function (ret) {
            username = ret.results[0].login.username;
        }
    });
    if (!username) {
        username = "cherub135";
    }
    const term = new EmulatedTerminal(document.getElementById('terminal'), username, {
        children: {
            "chat.sh": {
                content: "run /bin/chat",
                executable: true
            },
            "say.sh": {
                content: "run /bin/say",
                executable: true
            },
            "welcome.txt": {
                content: '\r\n' +
                    ' ______    ___  _____ ______ \r\n' +
                    '|      |  /  _]/ ___/|      |\r\n' +
                    '|      | /  [_(   \\_ |      |\r\n' +
                    '|_|  |_||    _]\\__  ||_|  |_|\r\n' +
                    '  |  |  |   [_ /  \\ |  |  |  \r\n' +
                    '  |  |  |     |\\    |  |  |  \r\n' +
                    '  |__|  |_____| \\___|  |__|  \r\n' +
                    '                             \r\n'
            },
            "notes": {
                children: {
                    note1: {
                        content: "This is a note!"
                    },
                    folder1: {
                        children: {}
                    }
                }
            }
        }
    }, commands);
    term.resolverChain = {
        possibilities: commands,
        children: {
            cat: {
                possibilities: () => term.fsManager.listCurrentDir(),
                criteria: (args) => args.length === 1
            },
            cd: {
                possibilities: () => term.fsManager.listCurrentDir(),
                criteria: (args) => args.length === 1
            },
            run: {
                possibilities: () => term.fsManager.listCurrentDir(),
                criteria: (args) => args.length === 1
            }
        }
    };
    term.audioRunning = document.getElementById('audio-running');
    term.audioRunning.addEventListener('timeupdate', function () {
        const buffer = .55;
        if (this.currentTime > this.duration - buffer) {
            this.currentTime = 0;
            this.play();
        }
    });
    if (webglSupport()) {
        const srcCanvases = Array.from(document.getElementsByTagName('canvas'));
        term.renderManager.addCanvas(srcCanvases[2]);
        term.renderManager.addCanvas(srcCanvases[0]);
        term.renderManager.cursorCanvas = srcCanvases[1];
    }
    const start = function () {
        return __awaiter(this, void 0, void 0, function* () {
            if (term.started) {
                return;
            }
            term.started = true;
            if (location.hostname === 'localhost') {
                term.renderManager.renderCursor = true;
                term.write(LE + LE + term.currentPrompt);
                term.enable();
                document.documentElement.onkeyup = undefined;
                return;
            }
            if (!term.settings.disableFullscreen)
                yield document.documentElement.requestFullscreen();
            if (!term.settings.disableAudio) {
                const audioStart = document.getElementById('audio-start');
                setTimeout(() => {
                    term.audioRunning.currentTime = 0;
                    term.audioRunning.play();
                }, 6800);
                yield audioStart.play();
            }
            term.write(LE);
            const start = performance.now();
            yield sleep(50);
            term.renderManager.renderCursor = true;
            setTimeout(function () {
                return __awaiter(this, void 0, void 0, function* () {
                    term.write(LE);
                    yield sleep(50);
                    const motd = "  _____           _____         _  _   \r\n" +
                        " |  __ \\       _ |_   _|       (_)| |  \r\n" +
                        " | |__) | ___ (_)  | |   _ __   _ | |_ \r\n" +
                        " |  _  / / _ \\     | |  | '_ \\ | || __|\r\n" +
                        " | | \\ \\|  __/ _  _| |_ | | | || || |_ \r\n" +
                        " |_|  \\_\\\\___|(_)|_____||_| |_||_| \\__|\r\n" +
                        "                                       \r\n";
                    var i = 0;
                    while (i < motd.length) {
                        const jump = randomNumber(5, 30);
                        let toWrite = motd.substring(i, i + jump > motd.length ? motd.length : i + jump);
                        if (toWrite[toWrite.length - 1] === '\r') {
                            toWrite += '\n';
                            i++;
                        }
                        term.write(toWrite);
                        i += jump;
                        if (5900 - (performance.now() - start) > 600)
                            yield sleep(randomNumber(100, 250));
                    }
                    term.write("Last login: " + term.lastLogin.toUTCString() + "\r\n");
                    yield sleep(5900 - (performance.now() - start));
                    term.write(term.currentPrompt);
                    term.enable();
                    document.documentElement.onkeyup = undefined;
                });
            }, 2000);
        });
    };
    term.events.addListener('disable', () => {
        setTimeout(() => {
            document.documentElement.onkeyup = start;
        }, 200);
    });
    document.documentElement.onclick = start;
    document.documentElement.onkeyup = start;
});
