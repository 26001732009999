import { extend, LE } from '../util/util';
import { AnsiCodes, Colors } from '../util/ansi';
import { Chat } from '../program/chat';
import { Say } from '../program/say';
function makeFSPrefix(fsItem, uname, fname) {
    return (fsItem.children ? 'd' : '-') + 'rw' + (fsItem.executable ? 'x' : '-') + '. ' + uname + ' ' +
        fsItem.date.toLocaleString('en-us', {
            month: 'short'
        }) + ' ' +
        fsItem.date.toLocaleString('en-us', {
            day: '2-digit'
        }) + ' ' +
        fsItem.date.toLocaleString('en-us', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        }) + ' ' + (fsItem.children ? Colors.BLUE + fname + Colors.END + '/' : (fsItem.executable ? Colors.GREEN + fname + Colors.END : fname)) + LE;
}
export const commands = {
    ls: {
        text: 'List all files in the current directory.',
        execute: (term, ret) => {
            let lsResult = makeFSPrefix(term.fsManager.currentItem, term.username, '.');
            lsResult += makeFSPrefix(term.fsManager.currentParent ? term.fsManager.currentParent : term.fsManager.currentItem, term.fsManager.currentParent ? term.username : extend('root', term.username.length), '..');
            const currDir = term.fsManager.listCurrentDir();
            for (const fileName in currDir) {
                const file = currDir[fileName];
                lsResult += makeFSPrefix(file, term.username, fileName);
            }
            ret.resolve(lsResult);
        }
    },
    cat: {
        text: 'Print the given file.',
        execute: (term, ret, args) => {
            if (args.length !== 1) {
                ret.resolve('Wrong number of arguments!');
            }
            const fileName = args[0];
            const currDir = term.fsManager.listCurrentDir();
            if (currDir[fileName] !== undefined) {
                const file = currDir[fileName];
                if (file.children) {
                    ret.resolve('cat: ' + fileName + ': Is a directory');
                    return;
                }
                ret.resolve(file.content);
            }
            ret.resolve('cat: File "' + fileName + '" not found.');
        }
    },
    cd: {
        text: 'Change directory.',
        execute: (term, ret, args) => {
            if (args.length === 0) {
                term.fsManager.changeNodeRoot();
                term.updatePrompt();
                ret.resolve('');
                return;
            }
            if (args.length !== 1) {
                ret.resolve('Wrong number of arguments!');
            }
            const fileName = args[0];
            const currDir = term.fsManager.listCurrentDir();
            if (currDir[fileName] !== undefined) {
                const file = currDir[fileName];
                if (file.content) {
                    ret.resolve('cd: ' + fileName + ': Is a file');
                    return;
                }
                term.fsManager.changeNode(fileName);
                term.updatePrompt();
                ret.resolve('');
            }
            else {
                switch (fileName) {
                    case '.':
                        ret.resolve('');
                        return;
                    case '..':
                        if (!term.fsManager.currentParent) {
                            ret.resolve('cd: Permission denied');
                            return;
                        }
                        term.fsManager.changeNodeParent();
                        term.updatePrompt();
                        ret.resolve('');
                        return;
                    case '~':
                        term.fsManager.changeNodeRoot();
                        term.updatePrompt();
                        ret.resolve('');
                        return;
                }
            }
            ret.resolve('cd: Directory "' + fileName + '" not found.');
        }
    },
    run: {
        text: "Execute a program.",
        execute: (term, ret, args) => {
            if (args.length === 0) {
                ret.resolve('Wrong number of arguments!');
            }
            const fileName = args[0];
            const currDir = term.fsManager.listCurrentDir();
            if (currDir[fileName] !== undefined) {
                const file = currDir[fileName];
                if (file.children) {
                    ret.resolve('run: ' + fileName + ': Is a directory.');
                    return;
                }
                if (!file.executable) {
                    ret.resolve('run: ' + fileName + ': Not executable.');
                    return;
                }
                switch (fileName) {
                    case 'chat.sh':
                        args.shift();
                        new Chat().execute(term, args);
                        return;
                    case 'say.sh':
                        args.shift();
                        new Say().execute(term, args);
                        return;
                }
            }
            ret.resolve('run: File "' + fileName + '" not found.');
        }
    },
    reboot: {
        text: "Reboots the system.",
        execute: (term) => {
            setTimeout(() => {
                term.audioRunning.pause();
            }, 100);
            if (!term.settings.disableAudio)
                document.getElementById('audio-stop').play();
            term.clear();
            term.write(AnsiCodes.CLEAR);
            term.disable();
            term.started = true;
            setTimeout(() => {
                location.reload();
            }, 3000);
        }
    },
    exit: {
        text: "Log out of your current session.",
        execute: (term) => {
            setTimeout(() => {
                term.audioRunning.pause();
            }, 100);
            if (!term.settings.disableAudio)
                document.getElementById('audio-stop').play();
            term.clear();
            term.write(AnsiCodes.CLEAR);
            term.disable();
            if (document.fullscreenElement !== null)
                document.exitFullscreen();
        }
    },
    clear: {
        text: 'Clears the screen.',
        execute: (term, ret) => {
            term.clear();
            term.write("\x1Bc");
            ret.resolve(LE + LE);
        }
    },
    'toggle-fs': {
        text: 'Toggles fullscreen.',
        execute: (term, ret) => {
            if (localStorage.getItem('disable-fs') === 'true') {
                term.settings.disableFullscreen = false;
                document.documentElement.requestFullscreen();
            }
            else {
                term.settings.disableFullscreen = true;
                if (document.fullscreenElement !== null)
                    document.exitFullscreen();
            }
            ret.resolve('Toggled fullscreen to ' + !term.settings.disableFullscreen + '.' + LE);
        }
    },
    'toggle-crt': {
        text: 'Toggles the CRT animation.',
        execute: (term, ret) => {
            term.termDiv.classList.toggle('crt-anim');
            term.settings.disableCrt = !term.termDiv.classList.contains('crt-anim');
            ret.resolve('Toggled animation to ' + !term.settings.disableCrt + '.' + LE);
        }
    },
    'toggle-audio': {
        text: 'Toggles audio.',
        execute: (term, ret) => {
            if (term.audioRunning.paused) {
                term.settings.disableAudio = false;
                term.audioRunning.play();
                term.setOption('bellStyle', 'sound');
            }
            else {
                term.settings.disableAudio = true;
                term.audioRunning.pause();
                term.setOption('bellStyle', 'none');
            }
            ret.resolve('Toggled audio to ' + !term.settings.disableAudio + '.\r\n');
        }
    },
    'help': {
        text: "Show help messages.",
        execute: (term, ret) => {
            let helpMsg = 'These shell commands are defined internally.\r\nType \'help\' to see this list.\r\n' +
                'The following commands exist:\r\n\r\n';
            for (const cmd in commands) {
                let cmdLine = '* ' + cmd + ':';
                cmdLine = extend(cmdLine, 20);
                helpMsg += cmdLine + commands[cmd].text + '\r\n';
            }
            ret.resolve(helpMsg);
        }
    }
};
