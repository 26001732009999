import { webglSupport } from '../util/util';
import * as $ from 'jquery';
const fx = require('glfx');
export class RenderManager {
    constructor(term) {
        if (webglSupport()) {
            $('.xterm-viewport').addClass('gl');
            this.fxCanvases = [];
            term.onRender(() => {
                this.renderAll();
            });
        }
        else {
            document.body.classList.add('non-gl');
            $('div#terminal').addClass('non-gl');
            term.onRender(() => {
                if (!this._renderCursor) {
                    $('span').removeClass('hide');
                    $('.xterm-cursor').addClass('hide');
                    this._dirty = true;
                }
                else if (this._dirty) {
                    this._dirty = false;
                    $('span').removeClass('hide');
                }
            });
        }
    }
    get cursor() {
        return document.getElementsByClassName('xterm-cursor')[0];
    }
    set cursorCanvas(canvas) {
        this.cursorFxCanvas = new FxCanvas(canvas);
        this.cursorFxCanvas.renderVignette = false;
        this.cursorFxCanvas.glCanvas.style.display = 'none';
    }
    addCanvas(canvas) {
        this.fxCanvases.push(new FxCanvas(canvas));
    }
    set renderCursor(val) {
        this._renderCursor = val;
        if (this._renderCursor && this._dirty) {
            this._dirty = false;
            $('span').removeClass('hide');
        }
        if (webglSupport())
            this.cursorFxCanvas.glCanvas.style.display = val ? 'block' : 'none';
    }
    renderAll() {
        for (const fxCanvas of this.fxCanvases) {
            fxCanvas.render();
        }
        this.cursorFxCanvas.render();
    }
}
export class FxCanvas {
    constructor(srcCanvas) {
        this.renderVignette = true;
        this.srcCanvas = srcCanvas;
        this.glCanvas = fx.canvas();
        this.texture = this.glCanvas.texture(srcCanvas);
        this.srcCanvas.parentNode.insertBefore(this.glCanvas, this.srcCanvas);
        this.glCanvas.style.zIndex = this.srcCanvas.style.zIndex;
        this.glCanvas.classList = this.srcCanvas.classList;
        this.glCanvas.width = this.srcCanvas.width;
        this.glCanvas.height = this.srcCanvas.height;
        this.srcCanvas.style.display = 'none';
    }
    render() {
        let w, h, hw, hh, wMod;
        w = this.srcCanvas.width;
        h = this.srcCanvas.height;
        hw = w / 2;
        hh = h / 2;
        wMod = w * 0.75;
        this.texture.loadContentsOf(this.srcCanvas);
        let temp = this.glCanvas.draw(this.texture)
            .bulgePinch(hw, hh, wMod, 0.12);
        if (this.renderVignette) {
            temp = temp.vignette(0.25, 0.5);
        }
        temp.update();
    }
}
